<template>
  <div class="bigbox">
    <div>
      <van-nav-bar title="充值" left-arrow @click-left="onClickLeft" />
    </div>
    <div>
      <div class="box">
        <van-cell-group>
          <van-field v-model="value" label="礼品码" placeholder="请输入" />
        </van-cell-group>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
export default {
  name: 'TaxiH5Recharge',

  data() {
    return {
      value: '',
    }
  },

  mounted() {},

  methods: {
    //返回
    onClickLeft() {
      Toast('返回')
    },
  },
}
</script>

<style lang="scss" scoped>
.bigbox{
  width: 100%;
   height: 100vh;
   background: #eee;
  .box{
   margin-top:10px;
   
  }
}
</style>